import React, { useEffect, useRef } from 'react'
import { select } from 'd3-selection'
import { line } from 'd3-shape'

const Line = ({ data, styles }) => {
    const lineRef = useRef(null)
    useEffect(() => {
        const lineEl = select(lineRef.current)
        lineEl.attr('d', line()(data))
    }, [data])
    return (
        <g>
            <path ref={lineRef} style={styles} />
        </g>
    )
}

export default Line

import * as React from 'react'
import Button from '@mui/material/Button'

import ShareIcon from '@mui/icons-material/Share'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import EmailIcon from '@mui/icons-material/Email'

const SimpleButton = ({ variant, label, customization, iconName, iconOrder = 'start', onClick }) => {
    const bgColor = customization?.backgroundColor ? customization?.backgroundColor : 'transparent'
    let buttonStyle = [
        {
            '&:hover': { cursor: 'pointer', border: customization?.border, backgroundColor: bgColor },
        },
    ]
    buttonStyle = [...buttonStyle, customization]
    return (
        <Button
            size="medium"
            className="button"
            variant={variant}
            sx={buttonStyle}
            startIcon={iconOrder === 'start' ? iconSelection(iconName) : null}
            endIcon={iconOrder === 'end' ? iconSelection(iconName) : null}
            onClick={onClick}
        >
            {label}
        </Button>
    )
}

export default SimpleButton

const iconSelection = (icon) => {
    switch (icon) {
        case 'Share':
            return <ShareIcon />
        case 'Question':
            return <QuestionMarkIcon />
        case 'ArrowForward':
            return <ArrowForwardIcon />
        case 'KeyboardArrow':
            return <KeyboardArrowDownIcon />
        case 'Download':
            return <DownloadForOfflineIcon />
        case 'Chat':
            return <ChatOutlinedIcon />
        case 'Add':
            return <AddCircleIcon fontSize="large" />
        case 'Remove':
            return <RemoveCircleIcon />
        case 'ReadMore':
            return <ReadMoreIcon />
        case 'Email':
            return <EmailIcon />
    }
}

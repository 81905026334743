import { Topics, Races, Status } from './enum'

export const ColorTopics = {
    Purple: '#773dbd',
    DarkBlue: '#275A96',
    Turqoise: '#008AB7',
    Green: '#308967',
    Yellow: '#e38517',
    Gray: '#6F6E72',
}

export const ColorViz = {
    Purple: '#95427b',
    DarkBlue: '#4742be',
    Turqoise: '#56a2cc',
    Green: '#308967',
    Yellow: '#e38517',
    Gray: '#6F6E72',
}

export const ColorGraphViz = {
    Silver: '#c6c3ce',
    Gray: '#d7d6e0',
    LightGray: '#f0eff7',
    White: '#FFFFFF',
    DarkGray: '#424245',
}

export const ColorLines = {
    LightGray: '#e0e1e3',
    DarkGray: '#424245',
    DarkBlue: '#121a50',
}

export const ComparisonColors = {
    Positive: '#008AB7',
    Negative: '#DE7610',
    Selected: '#275A96',
}

export const ButtonsColors = {
    Selected: '#424245',
    SelectedHovered: '#252527',
    Unselected: '#F0EFF7',
    UnselectedHovered: '#f2f2f4',
    Instructions: '#008AB7',
    InstructionsBg: '#E0F1FD',
}

export const ColorStatus = {
    Green: '#7EA36C',
    Red: '#e34f72',
    Orange: '#DE7610',
    Gray: '#6F6E72',
}

export const BG_COLOR = '#522d6d'

export const COLOR_MAP_TOPICS = {
    [Topics.AccessOpportunity]: ColorTopics.Green,
    [Topics.BusinessDevelopment]: ColorTopics.DarkBlue,
    [Topics.HumanCapital]: ColorTopics.Turqoise,
    [Topics.InclusiveGrowth]: ColorTopics.Purple,
    [Topics.Neighborhoods]: ColorTopics.Green,
    [Topics.SenseBelonging]: ColorTopics.Yellow,
    [Topics.Demographics]: ColorTopics.Gray,
}

export const COLOR_MAP_VIZ = {
    [Races.White]: ColorViz.Green,
    [Races.Black]: ColorViz.Yellow,
    [Races.Hispanic]: ColorViz.DarkBlue,
    [Races.Asian]: ColorViz.Purple,
    [Races.Indigenous]: ColorViz.Turqoise,
    [Races.MajorityWhite]: ColorViz.Green,
    [Races.MajorityPOC]: ColorViz.Yellow,
    [Races.All]: 'red',
    [Races.Unknown]: ColorViz.Gray,
}

export const COLOR_MAP_STATUS = {
    [Status.completed]: ColorStatus.Green,
    [Status.noApplicable]: ColorStatus.Gray,
    [Status.progress]: ColorStatus.Orange,
    [Status.noData]: ColorStatus.Red,
}

import { ToggleOptions, MigrationToggleOptions, QueryParam, Units, Decimals, BarchartModes } from './enum'

export const DATA_DIR = '/data'

export const TOGGLE_BUTTONS = [
    { id: ToggleOptions.Comparison, label: 'Current' },
    { id: ToggleOptions.Time, label: 'Change over time' },
]

export const TOGGLE_BUTTONS_MIGRATION = [
    { id: MigrationToggleOptions.InOut, label: 'In and out migration' },
    { id: MigrationToggleOptions.Net, label: 'Net domestic migration' },
    { id: ToggleOptions.Time, label: 'Net domestic migration over time' },
]

export const MIGRATION_RATES_TOOLTIPS = [
    {
        id: 'sb3_inflow_int_rate',
        label: '<strong>{VALUE}</strong> of every 1,000 working-age <strong>{LABEL}</strong> adults moved to the region from outside the United States',
    },
    {
        id: 'sb3_inflow_dom_rate',
        label: '<strong>{VALUE}</strong> of every 1,000 working-age <strong>{LABEL}</strong> adults moved to the region from within the United States',
    },
    {
        id: 'sb3_outflow_rate',
        label: '<strong>{VALUE}</strong> of every 1,000 working-age <strong>{LABEL}</strong> adults left the region',
    },
    {
        id: 'sb3_net_rate',
        label: 'On net, <strong>{VALUE}</strong> of every 1,000 working-age <strong>{LABEL}</strong> adults have moved TO/FROM the Minneapolis-St. Paul metro area in the past year',
    },
]

export const QP_DEFAULT = {
    [QueryParam.View]: ToggleOptions.Comparison,
    [QueryParam.BarChartMode]: BarchartModes.Regular,
    [QueryParam.CulturalCommunity]: '',
}

export const TICKS_FORMAT = {
    [Units.percent]: '%',
    [Units.dollars]: '$',
}

export const DECIMALS = {
    [Decimals.Zero]: 0,
    [Decimals.One]: 1,
    [Decimals.Two]: 2,
}

export const OPACITY = {
    childrenOpacity: 0.3,
    parentOpacity: 0.6,
}

export const PERCENT_TICK_VALUES = [0, 20, 40, 60, 80, 100]

export const CEI_WEBSITE = 'https://www.centerforeconomicinclusion.org/'
export const CEI_CONTACT_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSccCcZ6lU-eMctijf1wYEorcILLPrsgCJcCOsXQ4noWZl0yRA/viewform?usp=sf_link'
export const CEI_INTRO_INDICATORS = 'https://www.centerforeconomicinclusion.org/s/Introduction_Indicators-for-an-Inclusive-Regional-Economy_June-2022-at4y.pdf'
export const CEI_INDICATORS_METHODOLOGY = 'https://www.centerforeconomicinclusion.org/s/Methodology_Indicators-for-an-Inclusive-Regional-Economy_June-2022-rz82.pdf'
export const CEI_DATA_DOWNLOAD = 'https://www.centerforeconomicinclusion.org/s/2022_CEI_Indicators_Data.xlsx'
export const CEI_MAIL_ADDRESS = 'info@centerforeconomicinclusion.org'

export const GRAPHICACY_WEBSITE = 'https://www.graphicacy.com/'

export const TOP_NAV_BAR_OPTIONS = [
    { id: 0, label: '+', children: [], url: CEI_WEBSITE },
    { id: 1, label: 'Consulting', children: [], url: 'https://www.centerforeconomicinclusion.org/consulting-services' },
    {
        id: 2,
        label: 'Index',
        children: [
            {
                id: 2.1,
                label: 'Racial equity dividends index',
                url: 'https://www.centerforeconomicinclusion.org/racial-equity-dividends-index',
            },
            {
                id: 2.2,
                label: '2022 racial equity dividends index report',
                url: 'https://www.centerforeconomicinclusion.org/racial-equity-dividends-index-2022-report',
            },
            {
                id: 2.3,
                label: 'Faqs',
                url: 'https://www.centerforeconomicinclusion.org/redi-faqs',
            },
            {
                id: 2.4,
                label: 'Index brochure',
                url: 'https://static1.squarespace.com/static/5ae221af96d4558ab105ea0b/t/61e1ba0994fa3b43c792a45b/1642183177964/CEI+Racial+Equity+Dividends+Index+Brochure.pdf',
            },
        ],
        url: 'https://www.centerforeconomicinclusion.org/racial-equity-dividends-index',
    },
    {
        id: 3,
        label: 'Research',
        children: [
            {
                id: 3.1,
                label: '2022 Indicators for an inclusive regional economy',
                url: '/',
            },
        ],
        url: '/',
    },
    {
        id: 4,
        label: 'News',
        children: [
            {
                id: 4.1,
                label: 'Media',
                url: 'https://www.centerforeconomicinclusion.org/media',
            },
            {
                id: 4.2,
                label: 'Publications',
                url: 'https://www.centerforeconomicinclusion.org/newsletters',
            },
            {
                id: 4.3,
                label: 'Restaurant directory',
                url: 'https://www.centerforeconomicinclusion.org/2021-restaurant-directory',
            },
        ],
        url: 'https://www.centerforeconomicinclusion.org/media',
    },
    {
        id: 5,
        label: 'Advocacy',
        children: [
            {
                id: 5.1,
                label: '2022 policy agenda',
                url: 'https://www.centerforeconomicinclusion.org/2022-policy-agenda',
            },
            {
                id: 5.2,
                label: 'Inclusive jobs growth fund',
                url: 'https://www.centerforeconomicinclusion.org/inclusive-job-growth-fund',
            },
            {
                id: 5.3,
                label: 'Racial equity impact notes',
                url: 'https://www.centerforeconomicinclusion.org/racial-equity-imact-notes',
            },
            {
                id: 5.4,
                label: 'Racially responsive actions for CEOs',
                url: 'https://www.centerforeconomicinclusion.org/racially-responsive-actions-for-ceos',
            },
        ],
        url: 'https://www.centerforeconomicinclusion.org/2022-policy-agenda',
    },
    {
        id: 6,
        label: 'Events',
        children: [
            {
                id: 6.1,
                label: 'Powering inclusion summit, June 21, 2022',
                url: 'https://www.centerforeconomicinclusion.org/registration-june-21-2022',
            },
            {
                id: 6.2,
                label: 'Schedule',
                url: 'https://www.centerforeconomicinclusion.org/center-events',
            },
            {
                id: 6.3,
                label: 'Reckoning to rise series',
                url: 'https://www.centerforeconomicinclusion.org/r2r-series',
            },
            {
                id: 6.4,
                label: 'Annual summit & activator series',
                url: 'https://www.centerforeconomicinclusion.org/powering-inclusion-series',
            },
        ],
        url: 'https://www.centerforeconomicinclusion.org/registration-june-21-2022',
    },
    { id: 7, label: 'Investors', children: [], url: 'https://www.centerforeconomicinclusion.org/investors' },
    {
        id: 8,
        label: 'About',
        children: [
            {
                id: 8.1,
                label: 'Who we are',
                url: 'https://www.centerforeconomicinclusion.org/who-we-are',
            },
            {
                id: 8.2,
                label: 'Our people',
                url: 'https://www.centerforeconomicinclusion.org/staff',
            },
            {
                id: 8.3,
                label: 'How we work',
                url: 'https://www.centerforeconomicinclusion.org/how-we-work',
            },
            {
                id: 8.4,
                label: 'Making the case',
                url: 'https://www.centerforeconomicinclusion.org/regional-data',
            },
        ],
        url: 'https://www.centerforeconomicinclusion.org/who-we-are',
    },
    {
        id: 9,
        label: 'Contact',
        children: [
            {
                id: 9.1,
                label: 'Contact',
                url: 'https://www.centerforeconomicinclusion.org/contact',
            },
            {
                id: 9.2,
                label: 'Speaker request, Tawanna Black',
                url: 'https://www.centerforeconomicinclusion.org/speaker-request-tawanna-black',
            },
            {
                id: 9.3,
                label: 'Careers',
                url: 'https://www.centerforeconomicinclusion.org/careers',
            },
            {
                id: 9.4,
                label: 'LOI - transportation equity policy group',
                url: 'https://www.centerforeconomicinclusion.org/equity-policy-group-loi',
            },
            {
                id: 9.5,
                label: 'Open RFPS',
                url: 'https://www.centerforeconomicinclusion.org/open-rfps',
            },
        ],
        url: 'https://www.centerforeconomicinclusion.org/contact',
    },
    {
        id: 10,
        label: 'Donate',
        children: [],
        url: 'https://www.givemn.org/organization/Economic-Inclusion',
    },
]

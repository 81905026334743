import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import ExternalLink from '../common/external-link'
import SimpleButton from '../controls/simple-button'

import { ButtonTypes } from '../../utils/enum'
import { CEI_WEBSITE, CEI_CONTACT_FORM, CEI_INTRO_INDICATORS, CEI_INDICATORS_METHODOLOGY, CEI_MAIL_ADDRESS, GRAPHICACY_WEBSITE } from '../../utils/const'

import './footer.scss'

const Footer = () => {
    const buttonStyle = {
        color: '#ffffff',
        border: `1px solid #ffffff`,
        textTransform: 'none',
        fontSize: '14px',
        margin: '5px 0px 5px 0px',
        justifyContent: 'start', //TOFIX: Look at how to solve this for mobile
    }

    const handleClick = (option) => {
        if (option === 'form') {
            window.open(CEI_CONTACT_FORM, '_blank')
        } else {
            window.open(CEI_WEBSITE, '_blank')
        }
    }
    return (
        <footer>
            <section className="container">
                <div className="wrapper">
                    <ExternalLink url={CEI_WEBSITE} sameTab={false}>
                        <StaticImage src="../../images/cei-logo-footer.png" alt="CEI logo" />
                    </ExternalLink>
                </div>
                <div className="wrapper">
                    <div className="title">Contact us</div>
                    <div className="content">
                        How are the Indicators shifting your thinking and actions?
                        <div>
                            <SimpleButton variant={ButtonTypes.Outlined} label={'Share feedback with us'} iconName={'Chat'} customization={buttonStyle} onClick={() => handleClick('form')} />
                        </div>
                        Need help turning this data into action? Learn how the Center can help your organization today.
                        <div>
                            <SimpleButton variant={ButtonTypes.Outlined} label={'Learn more'} iconName={'ReadMore'} customization={buttonStyle} onClick={() => handleClick('info')} />
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="title">Download methodology</div>
                    <div className="content">
                        <div className="button-rows">
                            <SimpleButton
                                variant={ButtonTypes.Outlined}
                                label={'Introduction to the Indicators'}
                                iconName={'Download'}
                                customization={buttonStyle}
                                onClick={() => window.open(CEI_INTRO_INDICATORS, '_blank')}
                            />

                            <SimpleButton
                                variant={ButtonTypes.Outlined}
                                label={'Indicators Methodology'}
                                iconName={'Download'}
                                customization={buttonStyle}
                                onClick={() => window.open(CEI_INDICATORS_METHODOLOGY, '_blank')}
                            />
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="title">Download data</div>
                    <div className="content">
                        Want to go deeper?
                        <SimpleButton
                            variant={ButtonTypes.Outlined}
                            label={'Contact us for access to the full Indicators dataset'}
                            iconName={'Email'}
                            customization={buttonStyle}
                            onClick={() => (window.location = `mailto:${CEI_MAIL_ADDRESS}`)}
                        />
                    </div>
                </div>
            </section>
            <section>
                <div className="branding">
                    <span>Built by</span>
                    <ExternalLink url={GRAPHICACY_WEBSITE} sameTab={false}>
                        <StaticImage src="../../images/logo-graphicacy.png" alt="Graphicacy logo" />
                    </ExternalLink>
                </div>
            </section>
        </footer>
    )
}

export default Footer

export const Topics = {
    InclusiveGrowth: 'Inclusive Growth',
    BusinessDevelopment: 'Business Development',
    Neighborhoods: 'Neighborhoods',
    HumanCapital: 'Human Capital',
    AccessOpportunity: 'Access to Opportunity',
    SenseBelonging: 'Sense of Belonging',
    Demographics: 'Demographics',
}

export const Races = {
    White: 'White',
    Black: 'Black',
    Hispanic: 'Hispanic',
    Asian: 'Asian',
    Indigenous: 'Indigenous',
    All: 'All',
    Unknown: 'Unknown',
    MajorityWhite: 'Majority-white neighborhoods',
    MajorityPOC: 'Majority-people of color neighborhoods',
}

export const CulturalCommunities = {
    White: 'White',
    Black: 'Black',
    BlackAncestry: 'Black, American ancestry',
    Somali: 'Somali',
    Ethiopian: 'Ethiopian',
    Mexican: 'Mexican',
    PuertoRican: 'Puerto Rican',
    Hmong: 'Hmong',
    Hispanic: 'Hispanic',
    Asian: 'Asian',
    Indigenous: 'Indigenous',
    Indian: 'Indian',
    Vietnamese: 'Vietnamese',
    Chinese: 'Chinese',
    Korean: 'Korean',
    MajorityWhite: 'Majority-white neighborhoods',
    MajorityPOC: 'Majority-people of color neighborhoods',
}

export const QueryParam = {
    View: 'view',
    BarChartMode: 'barChartMode',
    CulturalCommunity: 'culturalCommunity',
}

export const ToggleOptions = {
    Comparison: 'comparison',
    Time: 'time',
}

export const MigrationToggleOptions = {
    InOut: 'inout',
    Net: 'net',
}

export const BarchartModes = {
    Regular: 'regular',
    Focused: 'focused',
}

export const ButtonTypes = {
    Text: 'text',
    Contained: 'contained',
    Outlined: 'outlined',
}

export const Units = {
    percent: 'percent',
    number: 'number',
    dollars: 'dollars',
}

//For development
export const Status = {
    completed: 'Completed',
    progress: 'In progress',
    noData: 'No data',
    noApplicable: 'N/A',
}

export const ConfidenceIntervalMessage = {
    Comparison: 'As this data is estimated, there is a 95% chance that the actual value for each datapoint is between the horizontal lines',
    ComparisonMigration: 'Horizontal lines indicate the lower (for out-migration) and upper (for in-migration) bounds of a 95% confidence interval. See note for details',
    Time: 'Shaded area represents 95% confidence intervals',
}

export const Decimals = {
    Zero: 'zero',
    One: 'one',
    Two: 'two',
}

export const Breakpoint = {
    LargeDesktop: 1400,
    Desktop: 1200,
    TabletWide: 1024,
    Tablet: 800,
    Mobile: 600,
}

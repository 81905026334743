import { useStaticQuery, graphql } from 'gatsby'

export const useIndicators = () => {
    const data = useStaticQuery(
        graphql`
            query AllIndicators {
                allMetadataCsv {
                    nodes {
                        Indicator_code
                        Indicator_long_name
                        Indicator_short_name
                        Indicator_comparison_name
                        Topic_area
                        Margin_of_error
                        Unit_of_measurement
                        Decimals
                        Geography
                        Year___Point_in_time
                    }
                }
            }
        `,
    )

    return data?.allMetadataCsv?.nodes
}

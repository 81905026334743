import { Units, Decimals } from './enum'

import { format } from 'd3-format'

export const valueFormatter = (value, unit) => {
    let formattedValue = Number(value)
    if (unit === Units.percent) {
        formattedValue = formattedValue * 100
    }
    return formattedValue
}

export const unitFormatter = (value, unit, decimals) => {
    if (unit === Units.percent) {
        switch (decimals) {
            case Decimals.Zero:
                return `${format('.0f')(value)}%`
            case Decimals.One:
                return `${format('.1f')(value)}%`
            case Decimals.Two:
                return `${format('.2f')(value)}%`
        }
    } else if (unit === Units.dollars) {
        return `$${format('.2s')(value)}`
    } else if (unit === Units.number) {
        switch (decimals) {
            case Decimals.Zero:
                return `${format('.0f')(value)}`
            case Decimals.One:
                return `${format('.1f')(value)}`
            case Decimals.Two:
                return `${format('.2f')(value)}`
        }
    } else return `${format(',')(value)}`
}

export const getAxisFormatters = (unit) => {
    if (unit === Units.percent) {
        return (d) => `${d}%`
    } else if (unit === Units.dollars) {
        return (d) => `$${format('.2s')(d)}`
    } else return (d) => `${d}`
}

export const truncateString = (label, n) => {
    return label.slice(0, n).concat('...')
}

import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'
import useMediaQuery from '@mui/material/useMediaQuery'

import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

import ExternalLink from '../common/external-link'

import { Breakpoint } from '../../utils/enum'
import { ColorTopics, ColorLines } from '../../utils/theme'
import { CEI_WEBSITE } from '../../utils/const'

import './header.scss'

const MENU_ITEM_POS = 150
//Header 2022
const Header = ({ navOptions, toggleVerticalNavBar, showVerticalNavBar }) => {
    const [hoveredOption, setHoveredOption] = useState('')
    const [childHoveredOption, setChildHoveredOption] = useState('')

    const isLargeDesktop = useMediaQuery(`(min-width:${Breakpoint.LargeDesktop}px)`)

    const handleClick = (option) => {
        navigate(option.url)
    }

    return (
        <header className="top-header">
            <div className="logo-wrapper">
                <ExternalLink url={CEI_WEBSITE} sameTab={true}>
                    <StaticImage src="../../images/cei-logo-navbar.jpg" alt="CEI logo" className="header-logo" />
                </ExternalLink>
            </div>
            <div className="header-nav">
                {isLargeDesktop && (
                    <nav className="main-nav">
                        {navOptions
                            .filter((option) => option.label !== 'Donate')
                            .map((option, idx) => {
                                const isHovered = hoveredOption === option.id
                                const lastChild = idx === navOptions.length - 2 //Removed donate option
                                return (
                                    <div className="nav-wrapper" key={`navbar-${option.id}`} onMouseEnter={() => setHoveredOption(option.id)} onMouseLeave={() => setHoveredOption('')}>
                                        <div
                                            className="nav-option"
                                            style={{ color: isHovered || option.label === 'Research' ? ColorTopics.Turqoise : ColorLines.DarkGray }}
                                            onClick={() => handleClick(option)}
                                        >
                                            <span>{option.label}</span>
                                        </div>
                                        {hoveredOption === option.id && (
                                            <div
                                                className="navbar-dropdown"
                                                style={{
                                                    right: lastChild ? MENU_ITEM_POS : '',
                                                }}
                                            >
                                                <div className="nav-option">
                                                    {option.children.map((child) => {
                                                        const isHovered = childHoveredOption === child.id

                                                        return (
                                                            <div
                                                                onMouseEnter={() => setChildHoveredOption(child.id)}
                                                                onMouseLeave={() => setChildHoveredOption('')}
                                                                key={`navbar-dropdown-${child.id}`}
                                                                className="nav-option-child"
                                                                style={{
                                                                    color:
                                                                        isHovered || child.label === '2022 Indicators for an inclusive regional economy' ? ColorTopics.Turqoise : ColorLines.DarkGray,
                                                                }}
                                                                onClick={() => handleClick(child)}
                                                            >
                                                                {child.label}
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        {navOptions
                            .filter((option) => option.label === 'Donate')
                            .map((option) => {
                                return (
                                    <div key={`navbar-${option.id}`} className="nav-option nav-button">
                                        <ExternalLink url={option.url} sameTab={false}>
                                            {option.label}
                                        </ExternalLink>
                                    </div>
                                )
                            })}
                    </nav>
                )}
                {!isLargeDesktop && (
                    <nav className="main-nav">
                        <div className="nav-wrapper">
                            <div className="nav-option" onClick={toggleVerticalNavBar}>
                                {!showVerticalNavBar && <MenuIcon fontSize="large" />}
                                {showVerticalNavBar && <CloseIcon fontSize="large" />}
                            </div>
                        </div>
                    </nav>
                )}
            </div>
        </header>
    )
}

export default Header

import * as React from 'react'

const ExternalLink = ({ url, children, sameTab, backgroundDark = false }) => {
    const target = sameTab ? '_self' : '_blank'
    const color = backgroundDark ? '#FFFFFF' : ''

    return (
        <a href={url} target={target} rel="noopener noreferrer" style={{ color: color }}>
            {children}
        </a>
    )
}

export default ExternalLink

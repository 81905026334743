import React, { useState } from 'react'
import { navigate } from 'gatsby'

import ExternalLink from '../common/external-link'

import { ColorTopics, ColorLines } from '../../utils/theme'

import './vertical-nav-bar.scss'

const VerticalNavBar = ({ navOptions }) => {
    const [hoveredOption, setHoveredOption] = useState('')
    const [childHoveredOption, setChildHoveredOption] = useState('')
    const [selectedOption, setSelectedOption] = useState('')
    const [showChildren, setShowChildren] = useState(false)

    const handleClick = (option) => {
        if (option.children && option.children.length > 0) {
            if (option.id !== selectedOption) {
                setShowChildren(true)
            } else {
                setShowChildren(!showChildren)
            }
            setSelectedOption(option.id)
        } else navigate(option.url)
    }

    return (
        <header className="top-header-mobile">
            <div className="header-nav">
                <nav className="main-nav">
                    {navOptions
                        .filter((option) => option.label !== 'Donate')
                        .map((option) => {
                            const isHovered = hoveredOption === option.id
                            const isSelected = selectedOption === option.id
                            const sign = selectedOption === option.id && showChildren ? '-' : '+'
                            const optionLabel = option.children.length > 0 ? `${sign} ${option.label}` : option.label
                            return (
                                <div className="nav-wrapper" key={`navbar-${option.id}`} onMouseEnter={() => setHoveredOption(option.id)} onMouseLeave={() => setHoveredOption('')}>
                                    <div
                                        className="nav-option"
                                        style={{ color: isHovered || isSelected || option.label === 'Research' ? ColorTopics.Turqoise : ColorLines.DarkGray }}
                                        onClick={() => handleClick(option)}
                                    >
                                        <span>{optionLabel}</span>
                                    </div>
                                    {showChildren && selectedOption === option.id && option.children.length > 0 && (
                                        <div className="navbar-child-list">
                                            <div className="nav-option">
                                                {option.children.map((child) => {
                                                    const isHovered = childHoveredOption === child.id
                                                    return (
                                                        <div
                                                            key={`navbar-child-list-${child.id}`}
                                                            className="nav-option-child"
                                                            onClick={() => handleClick(child)}
                                                            onMouseEnter={() => setChildHoveredOption(child.id)}
                                                            onMouseLeave={() => setChildHoveredOption('')}
                                                            style={{
                                                                color: isHovered || child.label === 'Indicators of an inclusive regional economy' ? ColorTopics.Turqoise : ColorLines.DarkGray,
                                                            }}
                                                        >
                                                            {child.label}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    {navOptions
                        .filter((option) => option.label === 'Donate')
                        .map((option) => {
                            return (
                                <div key={`navbar-${option.id}`} className="nav-option nav-button">
                                    <ExternalLink url={option.url} sameTab={false}>
                                        {option.label}
                                    </ExternalLink>
                                </div>
                            )
                        })}
                </nav>
            </div>
        </header>
    )
}

export default VerticalNavBar

import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const SEO = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        image
                    }
                }
            }
        `,
    )

    const { title, description, siteUrl, author, image } = site.siteMetadata
    const defaultImage = new URL(image, siteUrl)
    const lang = 'en'

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            link={
                siteUrl
                    ? [
                          {
                              rel: 'canonical',
                              href: siteUrl,
                          },
                      ]
                    : []
            }
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: 'og:image',
                    content: defaultImage,
                },
                {
                    property: 'og:image:width',
                    content: '1200',
                },
                {
                    property: 'og:image:height',
                    content: '630',
                },
                {
                    name: `twitter:creator`,
                    content: author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: description,
                },
                {
                    name: `twitter:image`,
                    content: defaultImage,
                },

                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
            ]}
        />
    )
}

export default SEO
